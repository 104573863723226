type SocialXIconProps = {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
};

export const SocialXIcon: React.FC<SocialXIconProps> = ({
  width = "16",
  height = "16",
  color = "#999999",
  className = "",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Social Icons">
        <path
          id="Vector"
          d="M12.2174 1.26929H14.4663L9.55298 6.88495L15.3332 14.5266H10.8073L7.26253 9.89198L3.20647 14.5266H0.956125L6.21146 8.52002L0.666504 1.26929H5.30724L8.51143 5.50551L12.2174 1.26929ZM11.428 13.1805H12.6742L4.6301 2.54471H3.29281L11.428 13.1805Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default SocialXIcon;
