import React from "react";

type SocialDiscordIconProps = {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
};

export const SocialDiscordIcon: React.FC<SocialDiscordIconProps> = ({
  width = "16",
  height = "16",
  color = "#999999",
  className = "",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Social Icons">
        <path
          id="Vector"
          d="M13.5447 2.77046C12.5249 2.30254 11.4313 1.9578 10.2879 1.76035C10.2671 1.75654 10.2463 1.76606 10.2356 1.78511C10.0949 2.03525 9.93915 2.36158 9.83006 2.61808C8.60027 2.43397 7.37679 2.43397 6.17221 2.61808C6.0631 2.35588 5.90166 2.03525 5.76038 1.78511C5.74966 1.7667 5.72886 1.75717 5.70803 1.76035C4.56527 1.95717 3.47171 2.30191 2.45129 2.77046C2.44246 2.77427 2.43488 2.78063 2.42986 2.78887C0.355594 5.88778 -0.212633 8.91052 0.0661201 11.8958C0.0673814 11.9104 0.0755799 11.9244 0.086932 11.9332C1.45547 12.9383 2.78114 13.5484 4.08219 13.9528C4.10301 13.9592 4.12507 13.9516 4.13832 13.9344C4.44608 13.5141 4.72043 13.071 4.95565 12.6049C4.96953 12.5777 4.95628 12.5453 4.92791 12.5345C4.49275 12.3694 4.0784 12.1681 3.67982 11.9396C3.64829 11.9212 3.64577 11.8761 3.67477 11.8545C3.75865 11.7916 3.84255 11.7263 3.92264 11.6602C3.93713 11.6482 3.95732 11.6456 3.97435 11.6532C6.59286 12.8488 9.4277 12.8488 12.0153 11.6532C12.0323 11.645 12.0525 11.6475 12.0677 11.6596C12.1478 11.7256 12.2316 11.7916 12.3161 11.8545C12.3451 11.8761 12.3433 11.9212 12.3117 11.9396C11.9131 12.1726 11.4988 12.3694 11.063 12.5338C11.0346 12.5446 11.022 12.5777 11.0359 12.6049C11.2762 13.0703 11.5505 13.5135 11.8526 13.9338C11.8652 13.9516 11.8879 13.9592 11.9087 13.9528C13.2161 13.5484 14.5417 12.9383 15.9103 11.9332C15.9223 11.9244 15.9298 11.911 15.9311 11.8964C16.2647 8.44512 15.3723 5.44717 13.5655 2.78951C13.5611 2.78063 13.5535 2.77427 13.5447 2.77046ZM5.34668 10.0781C4.55833 10.0781 3.90876 9.35431 3.90876 8.46545C3.90876 7.57659 4.54574 6.85283 5.34668 6.85283C6.15392 6.85283 6.79721 7.58295 6.78459 8.46545C6.78459 9.35431 6.14761 10.0781 5.34668 10.0781ZM10.6632 10.0781C9.87484 10.0781 9.22526 9.35431 9.22526 8.46545C9.22526 7.57659 9.86222 6.85283 10.6632 6.85283C11.4704 6.85283 12.1137 7.58295 12.1011 8.46545C12.1011 9.35431 11.4704 10.0781 10.6632 10.0781Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default SocialDiscordIcon;
